import React from 'react'
import MediaQuery from "react-responsive";
import fashion01 from '../assets/images/fashion/01.jpg'
import fashion02 from '../assets/images/fashion/02.jpg'
import fashion03 from '../assets/images/fashion/03.jpg'
import fashion04 from '../assets/images/fashion/04.jpg'
import fashion05 from '../assets/images/fashion/05.jpg'
import fashion06 from '../assets/images/fashion/06.jpg'
import fashion07 from '../assets/images/fashion/07.jpg'
import fashion08 from '../assets/images/fashion/08.jpg'
import fashion09 from '../assets/images/fashion/09.jpg'
import fashion10 from '../assets/images/fashion/10.jpg'
import fashion11 from '../assets/images/fashion/11.jpg'
import fashion12 from '../assets/images/fashion/12.jpg'
import fashion13 from '../assets/images/fashion/13.jpg'
import fashion14 from '../assets/images/fashion/14.jpg'
import thumb01 from '../assets/images/fashion/01.jpg'
import thumb02 from '../assets/images/fashion/02.jpg'
import thumb03 from '../assets/images/fashion/03.jpg'
import thumb04 from '../assets/images/fashion/04.jpg'
import thumb05 from '../assets/images/fashion/05.jpg'
import thumb06 from '../assets/images/fashion/06.jpg'
import thumb07 from '../assets/images/fashion/07.jpg'
import thumb08 from '../assets/images/fashion/08.jpg'
import thumb09 from '../assets/images/fashion/09.jpg'
import thumb10 from '../assets/images/fashion/10.jpg'
import thumb11 from '../assets/images/fashion/11.jpg'
import thumb12 from '../assets/images/fashion/12.jpg'
import thumb13 from '../assets/images/fashion/13.jpg'
import thumb14 from '../assets/images/fashion/14.jpg'
import pcthumb01 from '../assets/images/fashion/thumbnail/01.jpg'
import pcthumb02 from '../assets/images/fashion/thumbnail/02.jpg'
import pcthumb03 from '../assets/images/fashion/thumbnail/03.jpg'
import pcthumb04 from '../assets/images/fashion/thumbnail/04.jpg'
import pcthumb05 from '../assets/images/fashion/thumbnail/05.jpg'
import pcthumb06 from '../assets/images/fashion/thumbnail/06.jpg'
import pcthumb07 from '../assets/images/fashion/thumbnail/07.jpg'
import pcthumb08 from '../assets/images/fashion/thumbnail/08.jpg'
import pcthumb09 from '../assets/images/fashion/thumbnail/09.jpg'
import pcthumb10 from '../assets/images/fashion/thumbnail/10.jpg'
import pcthumb11 from '../assets/images/fashion/thumbnail/11.jpg'
import pcthumb12 from '../assets/images/fashion/thumbnail/12.jpg'
import pcthumb13 from '../assets/images/fashion/thumbnail/13.jpg'
import pcthumb14 from '../assets/images/fashion/thumbnail/14.jpg'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Fashionnav from '../components/Fashionnav'

const DEFAULT_IMAGES = [
  {
    id: '1',
    source: fashion01,
    thumbnail: pcthumb01,
    caption: 'Photo 1',
    description: 'ANNDIRKIZM "DISTORTION" 2017SS',
  },
  {
    id: '2',
    source: fashion02,
    thumbnail: pcthumb02,
    caption: 'Photo 2',
    description: 'ANNDIRKIZM "DISTORTION" 2017SS',
  },
  {
    id: '3',
    source: fashion03,
    thumbnail: pcthumb03,
    caption: 'Photo 3',
    description: 'ANNDIRKIZM "LOST YOUTH" 2018SS',
  },
  {
    id: '4',
    source: fashion04,
    thumbnail: pcthumb04,
    caption: 'Photo 4',
    description: 'ANNDIRKIZM "LOST YOUTH" 2018SS',
  },
  {
    id: '5',
    source: fashion05,
    thumbnail: pcthumb05,
    caption: 'Photo 5',
    description: 'ANNDIRKIZM "LOST YOUTH" 2018SS',
  },
  {
    id: '6',
    source: fashion06,
    thumbnail: pcthumb06,
    caption: 'Photo 6',
    description: 'ANNDIRKIZM "LOST YOUTH" 2018SS',
  },
  {
    id: '7',
    source: fashion07,
    thumbnail: pcthumb07,
    caption: 'Photo 7',
    description: 'ishita 2017SS "Do we have any hope in the future?"',
  },
  {
    id: '8',
    source: fashion08,
    thumbnail: pcthumb08,
    caption: 'Photo 1',
    description: 'ishita 2017SS "Do we have any hope in the future?"',
  },
  {
    id: '9',
    source: fashion09,
    thumbnail: pcthumb09,
    caption: 'Photo 2',
    description: 'ishita 2017SS "Do we have any hope in the future?"',
  },
  {
    id: '10',
    source: fashion10,
    thumbnail: pcthumb10,
    caption: 'Photo 3',
    description: 'Nehanne MIHARA YASUHIRO 2018SS',
  },
  {
    id: '11',
    source: fashion11,
    thumbnail: pcthumb11,
    caption: 'Photo 4',
    description: 'Nehanne MIHARA YASUHIRO 2018SS',
  },
  {
    id: '12',
    source: fashion12,
    thumbnail: pcthumb12,
    caption: 'Photo 5',
    description: 'Nehanne MIHARA YASUHIRO 2018SS',
  },
  {
    id: '13',
    source: fashion13,
    thumbnail: pcthumb13,
    caption: 'Photo 6',
    description: 'Nehanne MIHARA YASUHIRO 2018SS',
  },
  {
    id: '14',
    source: fashion14,
    thumbnail: pcthumb14,
    caption: 'Photo 7',
    description: 'Nehanne MIHARA YASUHIRO 2018SS',
  },
]
const DEFAULT_IMAGES2 = [
  {
    id: '1',
    source: fashion01,
    thumbnail: thumb01,
    caption: 'Photo 1',
    description: 'ANNDIRKIZM "DISTORTION" 2017SS',
  },
  {
    id: '2',
    source: fashion02,
    thumbnail: thumb02,
    caption: 'Photo 2',
    description: 'ANNDIRKIZM "DISTORTION" 2017SS',
  },
  {
    id: '3',
    source: fashion03,
    thumbnail: thumb03,
    caption: 'Photo 3',
    description: 'ANNDIRKIZM "LOST YOUTH" 2018SS',
  },
  {
    id: '4',
    source: fashion04,
    thumbnail: thumb04,
    caption: 'Photo 4',
    description: 'ANNDIRKIZM "LOST YOUTH" 2018SS',
  },
  {
    id: '5',
    source: fashion05,
    thumbnail: thumb05,
    caption: 'Photo 5',
    description: 'ANNDIRKIZM "LOST YOUTH" 2018SS',
  },
  {
    id: '6',
    source: fashion06,
    thumbnail: thumb06,
    caption: 'Photo 6',
    description: 'ANNDIRKIZM "LOST YOUTH" 2018SS',
  },
  {
    id: '7',
    source: fashion07,
    thumbnail: thumb07,
    caption: 'Photo 7',
    description: 'ishita 2017SS "Do we have any hope in the future?"',
  },
  {
    id: '8',
    source: fashion08,
    thumbnail: thumb08,
    caption: 'Photo 1',
    description: 'ishita 2017SS "Do we have any hope in the future?"',
  },
  {
    id: '9',
    source: fashion09,
    thumbnail: thumb09,
    caption: 'Photo 2',
    description: 'ishita 2017SS "Do we have any hope in the future?"',
  },
  {
    id: '10',
    source: fashion10,
    thumbnail: thumb10,
    caption: 'Photo 3',
    description: 'Nehanne MIHARA YASUHIRO 2018SS',
  },
  {
    id: '11',
    source: fashion11,
    thumbnail: thumb11,
    caption: 'Photo 4',
    description: 'Nehanne MIHARA YASUHIRO 2018SS',
  },
  {
    id: '12',
    source: fashion12,
    thumbnail: thumb12,
    caption: 'Photo 5',
    description: 'Nehanne MIHARA YASUHIRO 2018SS',
  },
  {
    id: '13',
    source: fashion13,
    thumbnail: thumb13,
    caption: 'Photo 6',
    description: 'Nehanne MIHARA YASUHIRO 2018SS',
  },
  {
    id: '14',
    source: fashion14,
    thumbnail: thumb14,
    caption: 'Photo 7',
    description: 'Nehanne MIHARA YASUHIRO 2018SS',
  },
]

const siteTitle = 'Onodera Ryo | fashion'

const Fashionall = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      nonClass: '',
    }
  }
  toggleNon = () => {
    console.log("111");
    this.setState({
      nonClass: 'is-active',
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.toggleNon();
    },700);
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
        </Helmet>
        <div id="main">
        <div className="portrait">
        <nav>
          <p class="nendai">Fashion</p>
        </nav>
        <div class={`gall ${this.state.nonClass}`}>
          <MediaQuery query="(min-width: 767px)">
              <Gallery
                      images={DEFAULT_IMAGES.map(
                        ({ id, source, thumbnail, caption, description }) => ({
                          source,
                          thumbnail,
                          caption,
                          description,
                        })
                      )}
              />
            </MediaQuery>
            <MediaQuery query="(max-width: 767px)">
              <Gallery
                      images={DEFAULT_IMAGES2.map(
                        ({ id, source, thumbnail, caption, description }) => ({
                          source,
                          thumbnail,
                          caption,
                          description,
                        })
                      )}
              />
          </MediaQuery>
        </div>
        </div>
        </div>
      </Layout>
    )
  }
}

export default Fashionall